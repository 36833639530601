<template>
	<div class="pintuan-box pd16_15">
		<div class="flex alcenter space">

			<div class="flex alcenter">
				<img style="width: 20px; height: 20px;" class="mr10" v-if="datas.style.icon && datas.style.icon !='' " :src="datas.style.icon" />
				<img v-else style="width: 20px; height: 20px;" class="mr10" src="../../../../../assets/image/mall_home_tui@3x.png" />
				<span class="ft16 ftw600 cl-main">{{datas.style.title ? datas.style.title:'精选推荐'}}</span>
			</div>
			
			<div>
				<span class="ft12 cl-main">查看全部</span>
				<span class="iconfont iconarrowright_black ft12 cl-main ml5"></span>
			</div>
		</div>
		<div class="mt16">
			<a-spin :spinning="loading">
				<div v-if="calldata == null" class="plugin-empty" style="height: 120px;line-height: 120px;">暂无数据</div> 
				<div v-else class="flex wrap space">
					<div  v-for="(item,index) in calldata" :key="index" :class="{mt16:index > 1}" style="width: 150px;">
						<div class="product-img-box big">
							<img class="product-img" :src="item.cover_img" />
						</div>
						<div class="mt8 ft14 cl-main ftw600 text-over">{{item.name}}</div>
						<div class="mt8">
							<span class="ft12 cl-price">¥</span>
							<span class="ft16 ftw600 cl-price">{{item.price}}</span>
							<span class="ft12 cl-notice text-line ml5">¥{{item.original_price}}</span>
						</div>
						<div class="mt8 flex alcenter space">
							<span class="ft12 cl-notice">已售 {{item.fictitious_take_count}}</span>
							<span class="btn-buy" :style="{background:mainColor}">购买</span>
						</div>
					</div>
				</div>	
			</a-spin>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			mainColor:{
				type:String,
				default:'',
			},
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		data(){
			return{
				loading:false,
				
			}
		},
		computed:{
			calldata(){
				return  this.datas.datas;
			}
		},
		created(){
			
		},
		methods:{
			
		}
	}
</script>

<style>
	.pintuan-box{
		background: #FFFFFF;
		border-radius: 8px;
		line-height: 1;
		position: relative;
		overflow: hidden;
	}
</style>